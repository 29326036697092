import React from "react"
import CustomLink from "../LinkCustom"
import Logo from "../Logo"
import { siteMap } from "../../constants/site-map"
import styles from "./styles.module.scss"
import Instagram from "../../assets/instagram-logo.png"
import Facebook from "../../assets/facebook-logo.png"
import LinkedIn from "../../assets/linkedin-logo.png"

const Footer = ({ path }) => (
  <footer className={styles.footerWrapper}>
    <div className={styles.footerColumns}>
      <div className={styles.footerColumn}>
        <a href="https://app.candid.org/profile/10973647" target="_blank">
          <img src="https://widgets.guidestar.org/prod/v1/pdp/transparency-seal/10973647/svg" />{" "}
        </a>
        <a href="https://greatnonprofits.org/org/neighborshare">
          <img
            src="//cdn.greatnonprofits.org//img/2022-top-rated-awards-badge-embed.png?id=997384089"
            alt="NeighborShare Nonprofit Overview and Reviews on GreatNonprofits"
            title="2022 Top-rated nonprofits and charities"
            width="200px"
            height="auto"
          />
        </a>
      </div>
      <div className={styles.footerMain}>
        <CustomLink url="/" className={styles.logo} size="nav">
          <Logo />
        </CustomLink>
        <nav className={styles.nav}>
          {siteMap.map((item, index) => (
            <CustomLink key={index.toString()} size="nav" {...item}>
              {item.label}
            </CustomLink>
          ))}
        </nav>
        <nav className={styles.nav}>
          <CustomLink size="nav" scope="internal" url="/manage-subscription">
            My Account
          </CustomLink>
          <CustomLink size="nav" scope="external" url="mailto:help@nbshare.org">
            Contact Us
          </CustomLink>
          <CustomLink size="nav" scope="internal" url="/terms-of-service">
            Terms of Service
          </CustomLink>
          <CustomLink size="nav" scope="internal" url="/privacy-policy">
            Privacy Policy
          </CustomLink>
        </nav>
        <nav className={styles.nav}>
          <CustomLink
            size="nav"
            url="https://www.instagram.com/neighborshare/"
            scope="external"
          >
            <img src={Instagram} className={styles.social} alt="Instagram" />
          </CustomLink>
          <CustomLink
            size="nav"
            url="https://www.facebook.com/NeighborShareOrg"
            scope="external"
          >
            <img src={Facebook} className={styles.social} alt="Facebook" />
          </CustomLink>
          <CustomLink
            size="nav"
            url="https://www.linkedin.com/company/56400161"
            scope="external"
          >
            <img src={LinkedIn} className={styles.social} alt="LinkedIn" />
          </CustomLink>
        </nav>
        {path === "/" && (
          <span className={styles.workco}>
            Designed and developed in partnership with{" "}
            <CustomLink size="nav" url="http://work.co" scope="external">
              Work & Co.
            </CustomLink>
          </span>
        )}
        <span className={styles.nb501c3}>
          NeighborShare, Inc. is a registered 501(c)(3) non-profit organization.
          EIN: 85-0811667
        </span>
      </div>
      <div className={styles.footerColumn}></div>
    </div>
  </footer>
)

export default Footer
